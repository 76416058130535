




















































































































@import '~@/assets/styles/components/modal-cadastro';
@import '~@//assets/styles/components/formularios';

.datas-input{
  margin-top: 28px
}
.valor > .v-input {
  padding-top: 0;

}
.valor::v-deep {
  .v-text-field {
    padding: 0 !important;
  }
  position: relative;
  top: 1px;
  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
}
.br-btn {
  font-size: 14px !important;
}
.box-table .v-btn{
  height: 40px !important;
}
.title {
  padding: 0;
  color: #1cb4ff;
}
